import React, { useState } from "react";
import styles from "./styles.m.css";
import assetify from "@quintype/framework/assetify";
import NotFoundImage from "../../../../assets/images/not-found-image.png";
import { PAGE_TYPE } from "../../../constants";
import { GA } from "../../../integrations";

import { fetchCollection } from "../../../api";
import SliderFocusedCard from "../../rows/slider-focused-card";

const NotFoundPage = () => {
  const [collection, setCollection] = useState(false);
  React.useEffect(() => {
    GA.registerPageView(PAGE_TYPE.NOT_FOUND);
    fetchCollection("trending-at-vikatan", 0, 4, {}).then(response => {
      setCollection(response);
    });
  }, []);

  return (
    <div className={`main-page ${styles["main-container"]}`}>
      <div className={styles.base}>
        <div className={`container`}>
          <div className={styles["page-404"]}>
            <img src={assetify(NotFoundImage)} />
            <h1 className={styles.headline}>{`We are unable to find the page you're looking for.`}</h1>
            <p className={styles["sub-text"]}>
              {`Try exploring the navigation links above to locate what you're after, or use the search box at the top of
            the page.`}
            </p>
          </div>
        </div>
      </div>
      {collection && <SliderFocusedCard collection={collection} />}
    </div>
  );
};

export { NotFoundPage };
